import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="mt-24 flex flex-col">
      <h1 className="place-self-center">404: Not Found</h1>
      <StaticImage
        src="../images/empty-apartment.jpg"
        layout="constrained"
        className="w-full"
        alt="Empty Apartment"
        quality={80}
      />
      <p>
        You just hit a route that doesn&#39;t exist... Return{' '}
        <Link to="/" className="hover:text-red-500">
          home
        </Link>{' '}
        of visit the{' '}
        <Link to="/sitemap" className="hover:text-red-500">
          sitemap
        </Link>
        .
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
